import React from "react"

import Layout from "../components/layout"
import CommonDedicatedTeam from "../components/dedicated-team/CommonDedicatedTeam"

const DedicatedTeamPython = () => {
  return (
    <Layout pageTitle="Dedicated Python Developers">
      {({ width }) => <CommonDedicatedTeam type="Python" screenWidth={width} />}
    </Layout>
  )
}

export default DedicatedTeamPython
